<template>
    <div class="camera-access-container">
        <img class="logo-img" src="@/assets/images/teck-logo_white.png" />

        <div class="instructions">
            <img class="logo-instructions" src="@/assets/images/ar-icon.png" />
            <p >Tap “Allow” to start the AR experience.</p>
            <img class="poweredby-img" src="//cdn.8thwall.com/web/img/almostthere/v2/poweredby-horiz-white-2.svg" />
        </div>
    </div>
</template>

<script>

export default {
    components: {  },
    name: 'CameraAccess',
    props: [
        'isOOH'
    ],
	data: function() {
		return {
		}
	}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

.camera-access-container {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    z-index: 10;

    background: #0D2F76;
    // background: red;
}

.camera-access-container .logo-img {
    position: absolute;
    top: 30px;
	width: 161px;
    left: 50%;
    transform: translateX(-50%);
}

.camera-access-container .instructions {
    position: fixed;
    bottom: 45px;
    left: 50%;
    width: 80%;
    transform: translateX(-50%);

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    // text-align: center;
}

.camera-access-container .instructions p {
    font-family: 'Lota Grotesque';
    font-weight: 700;
    font-size: 15px;
    line-height: 19px;

    margin: 10px 0 50px;
    text-align: center;
}

.camera-access-container .poweredby-img {
    width: 35vw;
    max-width: 125px;
}
</style>