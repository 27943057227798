import * as THREE from 'three';

window.THREE = THREE;

import SpriteSheetTexture from './TextureAnimator';

import { stages } from './config';

const STATE_IN = 0;
const STATE_OUT = 1;

export default class MyEighthWall {

	constructor( iOS, cID, txtCID, callbacks ) {
		this.isIOS = iOS;
		this.canvasID = cID;
		this.textCanvasID = txtCID;
		this.callbacks = callbacks;
		this.currentStage = -1;

		this.currentState = STATE_OUT;

		this.isVisible = false;
		this.totalLoaded = 0;

		this.delta = 0;
		this.prevFrame = 0;
	}

	toggleVideoMuted = () => {
		this.video.muted = !this.video.muted;
	}

	restartVideo = () => {
		this.video.pause();
		this.video.currentTime = 0;
	}

	initText(  ) {

		for( let i=0; i < stages.length; i++ ) {
			if ( stages[ i ].type == "text" )
				stages[ i ].spriteSheets = this.generateSpriteSheets( stages[ i ] );
		}

		this.textMaterial = new THREE.MeshBasicMaterial({
			side: THREE.DoubleSide,
			transparent: true
		});

		const width = ( stages[ 1 ].spriteDataIn.frameW * 0.001 ) * 1.5;
		const height = ( stages[ 1 ].spriteDataIn.frameH * 0.001 ) * 1.5;

		this.textMesh = new THREE.Mesh(new THREE.PlaneGeometry( width, height, 5, 5 ), this.textMaterial );
		this.textMesh.overdraw = true;
	}

	generateSpriteSheets( stage ) {
		return [ new SpriteSheetTexture( stage.imageIN, stage.spriteDataIn ),
				new SpriteSheetTexture( stage.imageOUT, stage.spriteDataOut )];
	}

	restart = () => {
		this.currentStage = 0;
		this.restartVideo();

		for( let i=0; i < stages.length; i++ ) {
			if ( stages[ i ].type == "text" ) {
				stages[ i ].spriteSheets[ 0 ].restart();
				stages[ i ].spriteSheets[ 1 ].restart();
			}
		}
	}

	updateStage = () => {

		// Alternate the state
		this.currentState = 1 - this.currentState;

		if ( this.currentState === STATE_IN ) {
			// Only move to the next stage if we are animating in
			this.currentStage++;

			// Restart the experience if we are at the end
			if ( this.currentStage >= stages.length ) this.restart();

			if ( this.isVisible ) this.video.play();

			if ( stages[ this.currentStage ].type == "text" ) this.textMaterial.map = stages[ this.currentStage ].spriteSheets[ STATE_IN ].canvasTexture;
		}
		else {
			if ( stages[ this.currentStage ].type == "text" ) this.textMaterial.map = stages[ this.currentStage ].spriteSheets[ STATE_OUT ].canvasTexture;
		}

		if ( stages[ this.currentStage ].type == "text" ) {
			this.textMaterial.map.minFilter = THREE.LinearFilter; 
			this.textMaterial.map.needsUpdate = true;
		}
	}

	imageTargetPipelineModule = () => {

		const raycaster = new THREE.Raycaster()
		const tapPosition = new THREE.Vector2()

		this.initText();

		this.video = null;
		let videoObj;
	
		// Populates some object into an XR scene and sets the initial camera position. The scene and
		// camera come from xr3js, and are only available in the camera loop lifecycle onStart() or later.
		const initXrScene = ({ scene, camera }) => {

			// create the video element
			this.video = document.createElement('video')
			this.video.src = 'https://teck-lg-copper-ar.s3.us-west-2.amazonaws.com/Teck_Copper_Hospital_Installation_Poster.mp4'
			this.video.setAttribute('preload', 'auto')
			this.video.setAttribute('loop', 'false')

			this.video.muted = true;
			this.video.setAttribute('playsinline', '')
			this.video.setAttribute('webkit-playsinline', '')
			this.video.crossOrigin = 'anonymous'
	
			const texture = new THREE.VideoTexture(this.video)
			texture.minFilter = THREE.LinearFilter
			texture.magFilter = THREE.LinearFilter
			texture.format = THREE.RGBFormat
			texture.crossOrigin = 'anonymous'

			var ratios = {
				x: 1.015,
				y: 0.7293
			}
	
			videoObj = new THREE.Mesh(
				new THREE.PlaneGeometry( ratios.x, ratios.y ),
				new THREE.MeshBasicMaterial({ map: texture })
			)

			// Hide video until image target is detected.
			videoObj.visible = false
			scene.add(videoObj)
			this.video.load()
	
			// Add soft white light to the scene.
			// This light cannot be used to cast shadows as it does not have a direction.
			scene.add(new THREE.AmbientLight(0x404040, 5))

			// Do text setup and positioning
			this.updateStage();
			scene.add( this.textMesh )
	
			// Set the initial camera position relative to the scene we just laid out. This must be at a
			// height greater than y=0.
			camera.position.set(0, 3, 0)
		}
	
		// Places content over image target
		const showTarget = ({ detail }) => {
			// When the image target named 'video-target' is detected, play video.
			// This string must match the name of the image target uploaded to 8th Wall.
			if ( detail.name === 'teck_ar_image_target' ) {
				this.isVisible = true;

				videoObj.scale.set(detail.scale, detail.scale, detail.scale)
				videoObj.position.copy(detail.position)
				videoObj.quaternion.copy(detail.rotation)
				
				videoObj.visible = true
				
				// If the video needs to playout still, then play it
				if ( this.video.paused && !(this.video.currentTime >= stages[ this.currentStage ].videoEndTime) ) {
					this.video.play()
				}

				this.textMesh.scale.set( detail.scale, detail.scale, detail.scale );

				if ( stages[ this.currentStage ].type == "text" ) {
					if ( this.isIOS ) {
						this.textMesh.position.set( 
							videoObj.position.x + stages[ this.currentStage ].iOS.x,
							videoObj.position.y + stages[ this.currentStage ].iOS.y,
							videoObj.position.z + stages[ this.currentStage ].iOS.z
						)
					} else {
						this.textMesh.position.set( 
							videoObj.position.x + stages[ this.currentStage ].x,
							videoObj.position.y + stages[ this.currentStage ].y,
							videoObj.position.z + stages[ this.currentStage ].z
						)
					}

					this.textMesh.quaternion.copy( videoObj.quaternion )
					// this.textMesh.rotation.y += stages[ this.currentStage ].rot;

					this.textMesh.visible = true;
				}

				this.callbacks.targetVisible( true );
			}
		}
	
		// Hides the image frame when the target is no longer detected.
		const hideTarget = ({ detail }) => {
			if ( detail.name === 'teck_ar_image_target' ) {
				this.isVisible = false;

				this.video.pause()
				videoObj.visible = false

				this.textMesh.visible = false;

				this.callbacks.targetVisible( false );
			}
		}
	
		// Grab a handle to the threejs scene and set the camera position on pipeline startup.
		const onStart = ({ canvas }) => {
			const { scene, camera } = XR8.Threejs.xrScene()  // Get the 3js scene from XR
	
			initXrScene({ scene, camera })  // Add content to the scene and set starting camera position.
	
			// prevent scroll/pinch gestures on canvas
			canvas.addEventListener('touchmove', (event) => {
				event.preventDefault()
			})
	
			// Sync the xr controller's 6DoF position and camera paremeters with our scene.
			XR8.XrController.updateCameraProjectionMatrix({
				origin: camera.position,
				facing: camera.quaternion,
			})
		}

		// onUpdate is called once per camera loop prior to render. Any 3js geometry scene would
		// typically happen here.
		const onUpdate = ( event ) => {
			this.delta = Date.now() - this.prevFrame;

			if ( this.isVisible ) {
				// Logic to check if we need to flag to the UI that we are finished our current section's animation
				if ( !this.video.paused && this.video.currentTime >= stages[ this.currentStage ].videoEndTime ) {
					this.video.pause();
					this.callbacks.videoIntervalComplete( this.currentStage );

					if ( stages[ this.currentStage ].type == "hidden" ) {
						this.currentState = STATE_OUT;
					}
				}

				if ( stages[ this.currentStage ].type == "text" ) {
					// Check if the current animation has finished or not
					if ( !stages[ this.currentStage ].spriteSheets[ this.currentState ].isFinished ) {
						stages[ this.currentStage ].spriteSheets[ this.currentState ].update( this.delta );
					}
					else if ( this.currentState === STATE_OUT ) {
						this.callbacks.nextStage();
					}
				}
			}
			
			this.prevFrame = Date.now();
		}
	
		return {
			// Camera pipeline modules need a name. It can be whatever you want but must be
			// unique within your app.
			name: 'lg-hospital-copper-ar',
	
			// onStart is called once when the camera feed begins. In this case, we need to wait for the
			// XR8.Threejs scene to be ready before we can access it to add content. It was created in
			// XR8.Threejs.pipelineModule()'s onStart method.
			onStart,

			onUpdate,
	
			// Listeners are called right after the processing stage that fired them. This guarantees that
			// updates can be applied at an appropriate synchronized point in the rendering cycle.
			listeners: [
				{ event: 'reality.imagefound', process: showTarget },
				{ event: 'reality.imageupdated', process: showTarget },
				{ event: 'reality.imagelost', process: hideTarget },
			],
		}
	}

	cameraPermissionModule = () => {

		const onCameraStatusChange = ({ status }) => {

			if (status == 'requesting') {
				// myApplication.showCameraPermissionsPrompt()
				this.callbacks.requesting();
			} else if (status == 'hasStream') {
				// myApplication.dismissCameraPermissionsPrompt()
				this.callbacks.hasStream();
			} else if (status == 'hasVideo') {
				// myApplication.startMainApplictation()
				this.callbacks.hasVideo();
			} else if (status == 'failed') {
				// myApplication.promptUserToChangeBrowserSettings()
				this.callbacks.failed();
			}
		}

		return {
			name: 'lg-hospital-camera-startup',
			onCameraStatusChange,
		}

	}

	startXR8 = () => {
		// If your app only interacts with image targets and not the world, disabling world tracking can
		// improve speed.
		XR8.XrController.configure({ disableWorldTracking: true })
		XR8.addCameraPipelineModules([  // Add camera pipeline modules.
			// Existing pipeline modules.
			XR8.GlTextureRenderer.pipelineModule(),      // Draws the camera feed.
			XR8.Threejs.pipelineModule(),                // Creates a ThreeJS AR Scene.
			XR8.XrController.pipelineModule(),           // Enables SLAM tracking.
			XRExtras.AlmostThere.pipelineModule(),       // Detects unsupported browsers and gives hints.
			XRExtras.FullWindowCanvas.pipelineModule(),  // Modifies the canvas to fill the window.
			// XRExtras.Loading.pipelineModule(),           // Manages the loading screen on startup.
			XRExtras.RuntimeError.pipelineModule(),      // Shows an error image on runtime error.
			// Custom pipeline modules.
			this.imageTargetPipelineModule(),            // Shows the video when our image target is detected
			this.cameraPermissionModule(),
		])
	
		// Open the camera and start running the camera run loop.
		XR8.run({ canvas: document.getElementById( this.canvasID ) })
	}
}
