<template>
	<div class="desktop-container">
	</div>
</template>

<script>

export default {
	name: 'Desktop',
	components: {  },
	props: [
	],
	data: function() {
		return {
		}
	},
	computed: {
	}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

.desktop-container {
	z-index: 30;

	position: fixed;

	top: 0;
	left: 0;

	width: 100%;
	height: 100%;

	font-size: 20px;

	background-image: url('~@/assets/images/poster_final.png');
	background-size: cover;
	background-position: center center;
	background-repeat: no-repeat;
}

.desktop-container .info {
	position: absolute;

	display: flex;
	flex-direction: column;
	align-items: center;

	top: 50%;
	left: 50%;

	transform: translate( -50%, -50% );

	text-align: center;
}

.desktop-container .logo-img {
    position: relative;
    display: block;
	width: 161px;
    margin: 0 auto;
    margin-bottom: 45px;
}

.desktop-container .info p {
	max-width: 300px;
	margin-bottom: 50px;
}

.desktop-container .info img {
	max-width: 350px;
}
</style>
