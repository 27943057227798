export const stages = [
    // Intro
    {
        type: "hidden",
        x: 0,
        y: 0,
        z: 0.25,
        iOS: {
            x: 0,
            y: 0,
            z: 0.25,
        },
        rot: 0,
        videoEndTime: 2.15
    },
    // Bedside Rail
    {
        type: "text",
        x: -0.5,
        y: 0,
        z: 0.25,
        iOS: {
            x: -0.5,
            y: 0.25,
            z: 0,
        },
        rot: 0,
        imageIN: "https://teck-lg-copper-ar.s3.us-west-2.amazonaws.com/bedside-comp-in.png",
        imageOUT: "https://teck-lg-copper-ar.s3.us-west-2.amazonaws.com/bedside-comp-out.png",
        spriteSheets: [],
        spriteDataIn: {
            framesX: 8,
            framesY: 8,
            frameW: 256,
            frameH: 256,
            endFrame: 58
        },
        spriteDataOut: {
            framesX: 8,
            framesY: 5,
            frameW: 256,
            frameH: 256,
            endFrame: 40
        },
        videoEndTime: 5.15
    },
    // Over Table
    {
        type: "text",
        x: 0.5,
        y: -0.3,
        z: 0.25,
        iOS: {
            x: 0.5,
            y: 0.25,
            z: 0.3,
        },
        rot: 0,
        imageIN: "https://teck-lg-copper-ar.s3.us-west-2.amazonaws.com/table-comp-in.png",
        imageOUT: "https://teck-lg-copper-ar.s3.us-west-2.amazonaws.com/table-comp-out.png",
        spriteSheets: [],
        spriteDataIn: {
            framesX: 8,
            framesY: 8,
            frameW: 256,
            frameH: 256,
            endFrame: 58
        },
        spriteDataOut: {
            framesX: 8,
            framesY: 6,
            frameW: 256,
            frameH: 256,
            endFrame: 51
        },
        videoEndTime: 8.15
    },
    // Light Switch
    {
        type: "text",
        x: 0.15,
        y: 0.35,
        z: 0.25,
        iOS: {
            x: 0.15,
            y: 0.25,
            z: -0.35,
        },
        rot: 0,
        imageIN: "https://teck-lg-copper-ar.s3.us-west-2.amazonaws.com/lightswitch-comp-in.png",
        imageOUT: "https://teck-lg-copper-ar.s3.us-west-2.amazonaws.com/lightswitch-comp-out.png",
        spriteSheets: [],
        spriteDataIn: {
            framesX: 8,
            framesY: 8,
            frameW: 256,
            frameH: 256,
            endFrame: 57
        },
        spriteDataOut: {
            framesX: 8,
            framesY: 6,
            frameW: 256,
            frameH: 256,
            endFrame: 51
        },
        videoEndTime: 11.15
    },
    // IV Pole
    {
        type: "text",
        x: -0.4,
        y: 0.5,
        z: 0.25,
        iOS: {
            x: -0.45,
            y: 0.25,
            z: -0.55,
        },
        rot: 0,
        imageIN: "https://teck-lg-copper-ar.s3.us-west-2.amazonaws.com/iv-comp-in.png",
        imageOUT: "https://teck-lg-copper-ar.s3.us-west-2.amazonaws.com/iv-comp-out.png",
        spriteSheets: [],
        spriteDataIn: {
            framesX: 8,
            framesY: 8,
            frameW: 256,
            frameH: 256,
            endFrame: 57
        },
        spriteDataOut: {
            framesX: 8,
            framesY: 6,
            frameW: 256,
            frameH: 256,
            endFrame: 50
        },
        videoEndTime: 14.15
    }
]