<template>
	<div id="app">
		<EighthWall
			v-if="!isDesktop && (stateCameraAccess || stateAR)"
			v-on:cameraStateChanged="onCameraStateChanged"
			:state="state"
			:isIOS="isIOS"
		/>
		<!-- <Landing
			v-if="!isDesktop && stateHome"
			v-on:onStartClicked="onStartClicked"
		/> -->
		<CameraAccess v-if="!isDesktop && stateCameraAccess" />
		<Desktop v-if="isDesktop" />
	</div>
</template>

<script>
import EighthWall from "./components/EighthWall.vue";
// import Landing from "./components/Landing.vue";
import CameraAccess from "./components/CameraAccess.vue";
import Desktop from "./components/Desktop.vue";

import Bowser from "bowser";

const STATE_HOME = "home";
const STATE_CAMERA_ACCESS = "camera";
const STATE_AR = "ar";

export default {
	name: "App",
	components: {
		EighthWall,
		// Landing,
		CameraAccess,
		Desktop,
	},
	data: function () {
		return {
			state: STATE_CAMERA_ACCESS,
		};
	},
	computed: {
		stateHome: function () {
			return this.state === STATE_HOME;
		},
		stateCameraAccess: function () {
			return this.state === STATE_CAMERA_ACCESS;
		},
		stateAR: function () {
			return this.state === STATE_AR;
		},
		isDesktop: function () {
			return (
				Bowser.parse(window.navigator.userAgent).platform.type === "desktop"
			);
		},
		isIOS: function () {
			return Bowser.parse(window.navigator.userAgent).os.name === "iOS";
		},
	},
	created: function () {
		document.title = "Copper Stops the Spread AR";
	},
	methods: {
		onStartClicked: function () {
			this.state = STATE_CAMERA_ACCESS;
		},
		onCameraStateChanged: function (state) {
			if (state === "hasVideo") {

				this.state = STATE_AR;
			}
		},
	},
};
</script>

<style lang="scss">

@font-face {
	font-family: "Lota Grotesque";
	src: url("./assets/fonts/LotaGrotesque-Bold.woff2") format("woff2"),
		url("./assets/fonts/LotaGrotesque-Bold.woff") format("woff"),
		url("./assets/fonts/LotaGrotesque-Bold.otf") format("opentype");
}

html,
body {
	position: fixed;
	width: 100%;
	height: -webkit-fill-available;
	background: #0D2F76;
}

body {
	position: fixed;
	width: 100%;
	min-height: 100%;
	min-height: -webkit-fill-available;
	background: #0D2F76;
}

#app {
	font-family: "Lota Grotesque", Helvetica, Arial, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

	position: fixed;
	top: 0;
	left: 0;

	width: 100%;
	height: 100%;

	// text-align: center;
	color: #A9E2F5;

	overflow: hidden;
	overscroll-behavior: none;

	z-index: 5;
}
</style>
