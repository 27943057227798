export default class SpriteSheetTexture {

    constructor(imageURL, spriteData ) {

        this.timer;
        this.frameWidth = spriteData.frameW; this.frameHeight = spriteData.frameH;
        this.x = 0, this.y = 0, this.count = 0, this.startFrame = 0;
        this.endFrame = spriteData.endFrame || spriteData.framesX * spriteData.framesY;
        this.framesX = spriteData.framesX, this.framesY = spriteData.framesY;
        this.canvas = document.createElement('canvas');
        this.ctx = this.canvas.getContext('2d');
        this.canvasTexture = new THREE.CanvasTexture( this.canvas );
        this.img = new Image();

        this.img.crossOrigin = "anonymous"

        this.hasLoaded = false;

        this.isFinished = false;

        this.calcCanvasDimensions( this.frameWidth, this.frameHeight );

        this.img.onload = () => {
            this.hasLoaded = true;

            this.nextFrame();
        }

        this.img.src = imageURL;

        this.frameInterval = 33;
        this.curFrameTime = 0;
    }

    calcCanvasDimensions = ( width, height ) => {

        const ratio = window.devicePixelRatio;

        this.canvas.width = width * ratio;
        this.canvas.height = height * ratio;
        this.canvas.style.width = width + "px";
        this.canvas.style.height = height + "px";

        this.ctx.scale(ratio, ratio);

        // console.log( "Canvas DIMENS", width, height, ratio, this.canvas );
    }

    restart = () => {
        this.isFinished = false;
        this.count = 0;
        this.curFrameTime = 0;
    }

    nextFrame = () => {
        this.count++;

        if( this.count >= this.endFrame - 1 ) {
            // this would be if the animation needs to loop
            // this.count = 0;
            // Not looping so flag that we're finshed
            this.isFinished = true;
            return;
        }

        this.x = ( this.count % this.framesX ) * this.frameWidth;
        this.y = (( this.count / this.framesX )|0) * this.frameHeight;

        this.ctx.clearRect( 0, 0, this.frameWidth, this.frameHeight );
        this.ctx.drawImage( this.img, this.x, this.y, this.frameWidth, this.frameHeight, 0, 0, this.frameWidth, this.frameHeight);

        this.canvasTexture.needsUpdate = true;

        return true;
    }

    update = ( delta ) => {
        if ( this.hasLoaded ) {
            this.curFrameTime += delta;

            if ( this.curFrameTime > this.frameInterval ) {
                this.curFrameTime = 0;
                this.nextFrame();
            }
        }
    }
}